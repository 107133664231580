import Location from '@/domain/Location';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';
import Item from '@/domain/Item';
import CycleCountPreferences from '@/domain/cyclecount/CycleCountPreference';
import CloseCycleCountAndAdjustInventoryDTO from '@/dtos/cyclecount/CloseCycleCountAndAdjustInventoryDTO';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import PlannedPart from '../PlannedPart';

export enum CycleCountReportStatus {
    Open = 0,
    AwaitingApproval = 5,
    Closed = 10,
}

export default class CycleCountReport {
    public id!: number;

    public location!: Location;

    public createdDate!: Date;

    public startDate!: Date;

    public dueDate!: Date;

    public closedDate?: Date;

    public cycleCountItems: Array<CycleCountItem> = [];

    public status!: CycleCountReportStatus;

    constructor(init?: CycleCountReport) {
        if (init) {
            this.id = init.id;
            this.location = new Location(init.location);
            this.createdDate = new Date(init.createdDate);
            this.startDate = new Date(init.startDate);
            this.dueDate = new Date(init.dueDate);
            if (init.closedDate) this.closedDate = new Date(init.closedDate);
            this.cycleCountItems = init.cycleCountItems.map((c) => new CycleCountItem(c));
            this.status = init.status;
        }
    }

    public updateItemCount(item: Item | PlannedPart, count?: number) {
        let cycleCountItem: CycleCountItem | undefined;

        if (item instanceof Item) {
            cycleCountItem = this.cycleCountItems.find((i) => i.item?.id === item.id);
        } else {
            cycleCountItem = this.cycleCountItems.find((i) => i.plannedPart?.id === item.id);
        }

        if (cycleCountItem) {
            cycleCountItem.updateCount(count);
        } else {
            this.addItemCount(item, count);
        }
    }

    public addItemCount(item: Item | PlannedPart, count?: number) {
        let newCycleCountItem: CycleCountItem;

        if (item instanceof Item) {
            newCycleCountItem = new CycleCountItem({
                count,
                dateCounted: new Date(Date.now()),
                id: 0,
                item,
            } as CycleCountItem);
        } else {
            newCycleCountItem = new CycleCountItem({
                count,
                dateCounted: new Date(Date.now()),
                id: 0,
                plannedPart: item,
            } as CycleCountItem);
        }

        this.cycleCountItems.unshift(newCycleCountItem);
    }

    public closeReport() {
        this.status = CycleCountReportStatus.Closed;
        this.closedDate = new Date(Date.now());
    }

    get percentComplete(): number {
        return Math.ceil((this.totalCountedItems / this.totalItems) * 100);
    }

    get isCountComplete(): boolean {
        return this.uncountedItems.length === 0;
    }

    get totalItems(): number {
        return this.cycleCountItems.length;
    }

    get totalCountedItems(): number {
        return this.cycleCountItems.filter((c) => c.dateCounted).length;
    }

    get totalUncountedItems(): number {
        return this.uncountedItems.length;
    }

    get uncountedItems(): Array<CycleCountItem> {
        return this.cycleCountItems.filter((c) => !c.dateCounted);
    }

    get isPastDue(): boolean {
        return this.dueDate.getMilliseconds() <= Date.now();
    }

    get cycleCountPreferences(): CycleCountPreferences | undefined {
        return this.location.cycleCountPreferences;
    }

    get inventoryAdjustmentThreshold(): number | undefined {
        return this.cycleCountPreferences?.inventoryAdjustmentThreshold;
    }

    get adjustmentsAvailable(): number {
        return this.status === CycleCountReportStatus.Open ? this.cycleCountItems.filter((i) => i.isOutsideOfThreshold).length : 0;
    }

    get toCloseWithAdjustmentsDTO(): CloseCycleCountAndAdjustInventoryDTO {
        return new CloseCycleCountAndAdjustInventoryDTO(
            this.id,
            this.cycleCountItems.map((i) => i.toAdjustmentDTO),
        );
    }

    get statusText(): string {
        if (this.status === CycleCountReportStatus.Closed) return getTitleCaseTranslation('core.common.closed');
        if (this.status === CycleCountReportStatus.AwaitingApproval) return getTitleCaseTranslation('core.domain.awaitingReview');
        return getTitleCaseTranslation('core.domain.active');
    }
}
