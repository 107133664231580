import Item from '@/domain/Item';
import CycleCountAdjustment from '@/domain/cyclecount/CycleCountAdjustment';
import PlannedPart from '@/domain/PlannedPart';
import { CloseCycleCountItemAdjustmentDTO } from '@/dtos/cyclecount/CloseCycleCountAndAdjustInventoryDTO';

export default class CycleCountItem extends CycleCountAdjustment {
    public id: number;

    public item?: Item;

    public plannedPart?: PlannedPart;

    public count?: number;

    public systemCount?: number;

    private _dateCounted?: Date;

    private _isOutsideOfThreshold?: boolean;

    public underLoadPieces?: number;

    constructor(init: CycleCountItem) {
        super();
        this.id = init.id;
        this.count = init.count;
        this.item = init.item ? new Item(init.item) : undefined;
        this.plannedPart = init.plannedPart ? new PlannedPart(init.plannedPart) : undefined;
        this.systemCount = init.systemCount;
        this.underLoadPieces = init.underLoadPieces;
        this._isOutsideOfThreshold = init.isOutsideOfThreshold;

        if (init.dateCounted) this._dateCounted = new Date(init.dateCounted);
        if (init.count !== undefined && init.systemCount !== undefined) this.setInitialAdjustment(init.count, init.systemCount, init.underLoadPieces);
    }

    public resetCount() {
        this.count = undefined;
        this._dateCounted = undefined;
    }

    public updateCount(count?: number) {
        this.count = count;
        this._dateCounted = new Date(Date.now());
    }

    get dateCounted(): Date | undefined {
        return this._dateCounted;
    }

    get isOutsideOfThreshold(): boolean | undefined {
        return this._isOutsideOfThreshold;
    }

    get toAdjustmentDTO(): CloseCycleCountItemAdjustmentDTO {
        return new CloseCycleCountItemAdjustmentDTO({
            itemId: this.item?.id ?? 0,
            adjustment: this.inventoryAdjustment,
            count: this.newCount !== this.count ? this.newCount : undefined,
        });
    }

    public setInventoryAdjustment(value : number | undefined) {
        this.inventoryAdjustment = value;
    }
}
