import CycleCountReport from '@/domain/cyclecount/CycleCountReport';
import CycleCountReportApiService from '@/services/api/CycleCountReportApiService';
import SubmitCycleCountItemDTO from '@/dtos/cyclecount/SubmitCycleCountItemDTO';
import Transaction from '@/domain/Transaction';
import CycleCountSearchFilter from '@/dtos/filters/CycleCountSearchFilter';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import ResetCycleCountItemDTO from '@/dtos/cyclecount/ResetCycleCountItemDTO';

export default class CycleCountReportService {
    private cycleCountReportApiService: CycleCountReportApiService;

    constructor() {
        this.cycleCountReportApiService = new CycleCountReportApiService();
    }

    public async getCycleCountReportById(reportId: number): Promise<CycleCountReport | null> {
        const response = await this.cycleCountReportApiService.getCycleCountReportById(reportId);

        return response.data;
    }

    public async getOpenCycleCountReportByLocation(locationId: number): Promise<CycleCountReport | null> {
        const response = await this.cycleCountReportApiService.getOpenCycleCountReportByLocation(locationId);

        return response.data;
    }

    public async getUncountedCycleCountReportCountByLocation(locationId: number): Promise<number> {
        const response = await this.cycleCountReportApiService.getUncountedCycleCountReportCountByLocation(locationId);

        return response.data;
    }

    public async getCycleCountReportsByFilter(filter: CycleCountSearchFilter): Promise<GetResultSetWithCountDTO<CycleCountReport>> {
        const filterDto = filter.toDTO;
        const response = await this.cycleCountReportApiService.getCycleCountReportsByFilter(filterDto);

        return {
            resultSet: response.data.resultSet,
            totalMatchedRecords: response.data.totalMatchedRecords,
        };
    }

    public async submitCycleCountItem(dto: SubmitCycleCountItemDTO): Promise<{ success: boolean, report: CycleCountReport, transactions: Array<Transaction> }> {
        const response = await this.cycleCountReportApiService.submitCycleCountItem(dto);
        return {
            success: response.success,
            report: response.data.report,
            transactions: response.data.inboundDeliveryConfirmedTransactions,
        };
    }

    public async closeReport(reportId: number): Promise<boolean> {
        const response = await this.cycleCountReportApiService.closeReport(reportId);
        return response.success;
    }

    public async closeReportAndAdjustInventory(report: CycleCountReport): Promise<DataAccessResponse<boolean>> {
        const dto = report.toCloseWithAdjustmentsDTO;
        const response = await this.cycleCountReportApiService.closeReportAndAdjustInventory(dto);
        return response;
    }

    public async finalizeCounts(reportId: number): Promise<CycleCountReport | undefined> {
        const response = await this.cycleCountReportApiService.finalizeCounts(reportId);

        if (response.success) {
            return response.data;
        }

        return undefined;
    }

    public async resetCycleCountItem(dto: ResetCycleCountItemDTO): Promise<DataAccessResponse<CycleCountReport>> {
        const response = await this.cycleCountReportApiService.resetCycleCountItem(dto);

        return response;
    }

    public async reopenReportById(reportId: number): Promise<CycleCountReport | null> {
        const response = await this.cycleCountReportApiService.reopenReportById(reportId);
        return response.data;
    }
}
